/*
 * 업무구분: 활동
 * 화 면 명: MSPFS400M
 * 화면설명: 플라자별고객관리4400
 * 작 성 일: 2023.01.16
 * 작 성 자: 이지수
 */
<template>
  <ur-page-container class="fts" :show-title="false" title="플라자별고객관리" :topButton="true">
    <!-- header start -->
    <fs-header
      ref="fsHeader"
      :propObj="pHeaderObj"
    ></fs-header>
    <!-- header end -->

    <!-- fs-cust-info start -->
    <fs-cust-info
      v-show="false"
      ref="fsCustInfo"
      :propObj="pCustInfoObj"
      @fs-cust-name="fn_SetEdCustName"
    ></fs-cust-info>
    <!-- fs-cust-info end -->

    <!-- .fts-main -->
    <main class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="left gap40">
            <div class="wrap-input row">
              <label class="emphasis"> 고객번호 </label>
              <mo-text-field
                type="number"
                v-model="cust_no"
                @input="fn_CustInfo"
                placeholder=" "
                class="w150"
                maxlength="7"
                ref="custNo"
                />
              <mo-button @click="fn_cust_no_find" class="w61"> 고객정보 </mo-button>
              <mo-text-field v-model="cust_nm" class="w130" disabled />
            </div>
          </div>
          <div class="right">
            <div class="wrap-button row">
              <mo-button class="clear" @click="fn_init"> 초기화 </mo-button>
              <mo-button primary class="inquiry" @click="fn_searchList"> 조회 </mo-button>
            </div>
          </div>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table mt22">
          <table class="table col-type td-text-right col1419">
            <!-- <colgroup>
              <col width="120px" />
              <col width="193px" />
              <col width="120px" />
              <col width="193px" />
              <col width="120px" />
              <col width="*"/>
            </colgroup> -->
            <tbody>
              <tr>
                <th>고객정보</th>
                <td colspan="3" class="text-center">
                  {{ ds_detail.cust_info }}
                </td>
                <th>휴대폰</th>
                <td class="text-center"> {{ ds_detail.cell_no }} </td>
              </tr>
              <tr>
                <th>수익기여도</th>
                <td class="text-center">{{ fn_comma(ds_detail.yield_lev) }}</td>
                <th>고객등록일</th>
                <td class="text-center">{{ fn_dateFormat(ds_detail.reg_ymd )}}</td>
                <th>VOC여부</th>
                <td class="text-center">{{ ds_detail.voc_gb }}</td>
              </tr>
              <tr>
                <th>투자금액</th>
                <td class="text-center">{{ fn_comma(ds_detail.invest_bfcer_blnc_prnc) }}</td>
                <th>평가금액</th>
                <td class="text-center">{{ fn_comma(ds_detail.bfcer_eval_amt) }}</td>
                <th>수익률</th>
                <td class="text-center">{{ds_detail.yield_rt}}</td>
              </tr>
              <tr>
                <th>ELF투자</th>
                <td class="text-center">{{ ds_detail.elf_gb }}</td>
                <th>최종상담일</th>
                <td class="text-center">{{ fn_dateFormat(ds_detail.last_counsel_dt) }}</td>
                <th>고객성별</th>
                <td class="text-center">{{ds_detail.gender}}</td>
              </tr>
              <tr>
                <th>누적수익금</th>
                <td class="text-center">{{ fn_comma(ds_detail.accum_yield_amt) }}</td>
                <th>총입금액</th>
                <td class="text-center">{{ fn_comma(ds_detail.total_receipt_amt) }}</td>
                <th>총출금액</th>
                <td class="text-center">{{ fn_comma(ds_detail.total_payment_amt) }}</td>
              </tr>
              <tr>
                <th>고객구분</th>
                <td class="text-center">{{ ds_detail.cust_gb }}</td>
                <th>자택연락처</th>
                <td class="text-center">{{ ds_detail.home_telno }}</td>
                <th>마케팅동의</th>
                <td class="text-center">{{ ds_detail.marketing_gb }}</td>
              </tr>
              <tr>
                <th>우편물통보처</th>
                <td class="text-center">{{ ds_detail.bfcer_mail_trad_cd }}</td>
                <th>한줄메모</th>
                <td colspan="3">
                  <div class="row flex-sb">
                    <span>
                      {{ ds_detail.brief_memo_cntnt }}
                    </span>
                    <mo-button @click="fn_OpenPopup" :disabeld="btn_memo">등록</mo-button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="row gap18 w100p align-start">

          <div class="column">
            <div class="wrap-table-title">
              <div class="wrap-input row gap10">
                <span class="title-input">고객접촉날짜</span>
                <mo-date-picker v-model="trd_date" :bottom="false" class="datepicker-w152" :disabled="cust_nm == '' " />
              </div>
            </div>
            <div class="row">
              <div class="row-table step mt0">
                <div class="wrap-table h-scroll-222 w225">
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="text-center"> 고객접촉 </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, idx) in ds_contact" :key="idx" :class="selectedContact == idx ? 'selected' : ''" @click="fn_contact_click(idx)">
                        <td>{{item.text}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <i class="icon-arrowR"> </i>
                <div class="wrap-table h-scroll-222 w225">
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="text-center"> 고객관리 </th>
                      </tr>
                    </thead>
                    <tbody v-if="selectedContact != -1">
                      <tr v-for="(item, idx) in ds_management"
                      :key="idx"
                      :class="selectedManagement == idx ?'selected' : ''"
                      @click="fn_management_click(idx)" >
                        <td>{{item.cd_val_kra_nm}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="column w100p">
            <div class="wrap-table-title mt34">
              <h2>세부내용</h2>
            </div>
            <div class="wrap-table">
              <table class="table">
                <tbody>
                  <tr>
                    <td class="pad10"  v-if="selectedManagement != -1">
                      <div class="wrap-input row">
                        <mo-dropdown
                        :items="ds_group_no"
                        v-model="bfcer_cstgr_no"
                        :itemValue="'bfcer_cstgr_no'"
                        :itemText="'bfcer_cstgr_seq'"
                        @input="fn_groupList_onChange"
                        class="w150"
                        />
                        <mo-dropdown
                        :items="ds_group_seq_no"
                        v-model="acct_no"
                        :itemValue="'bfcer_accn_no'"
                        :itemText="'bfcer_accn_seq'"
                        @input="fn_group_seq_no_onChange($event)"
                        class="mw278"
                        />
                      </div>
                      <div class="wrap-input column">
                        <div class="row w100p flex-sb mt22 mb6">
                          <span class="title-input">세부내용(한글 300자 이내)</span>
                          <span class="title-input">{{inpt_prct}}%</span>
                        </div>
                        <mo-text-area
                          v-model="counsel_detail"
                          placeholder="입력하세요"
                          @input="fn_counsel_detail_change"
                          class="w100p textarea-h92"
                          :key="counsel_detail_key"
                        />
                      </div>
                      <div class="wrap-button row justify-end mt10">
                        <mo-button :disabled="btn_query" @click="fn_insert_detail">입력</mo-button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title flex-sb align-center">
          <h2>과거상담이력</h2>
          <mo-button :disabled="btn_delete" @click="fn_delete">삭제</mo-button>
        </div>
        <div class="wrap-table h-scroll">
          <table class="table row-type td-lh1-4">
            <thead>
              <tr>
                <th class="w50"></th>
                <th> 일자 </th>
                <th> 고객접촉 </th>
                <th> 고객관리 </th>
                <th> 세부내용(▲행선지 ■시간 ★고객연락처 ◆권유인 ▼내용) </th>
                <th> 펀드명 </th>
                <th> 입력자 </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, idx) in ds_counsel_history" :key="idx" :class="{'checked': row.checked == true}" @click="fn_grid_on_click(row)"> <!-- tr 터치 시 스타일 바뀌는 클릭 이벤트 추가 2023-04-13 jy -->
                <td>
                  <mo-checkbox v-model="row.checked"/>
                </td>
                <td> {{fn_dateFormat(row.cnsl_reg_ymd)}} </td>
                <td class="wsN"> {{row.bfcer_cnsl_busn_frst_cd}} </td>
                <td class="wsN"> {{row.bfcer_cnsl_busn_scd_cd}} </td>
                <td class="aL"> {{row.cust_cnsl_cntnt}} </td>
                <td> {{row.bfcer_fnd_nm}} </td>
                <td> {{row.cnsl_reg_staf_eno}} </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

    </main>
    <!-- //.fts-main -->
    <!-- popup401 -->
    <msp-fs-401p
      ref="popup401"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack"
      :popupObj="pPopup401Obj"
    ></msp-fs-401p>
    <!-- popup308 -->
    <msp-fs-308p
      ref="popup308"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_callBack308"
      :popupObj="pPopup308Obj"
    ></msp-fs-308p>

    <!-- alert modal popup -->
    <fs-alert-popup
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></fs-alert-popup>

  </ur-page-container>
</template>
<script>
/***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역
   ***********************************************************************************/
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
import FSCustInfo from '~/src/ui/fs/comm/FSCustInfo' // 고객정보 (공통)
import FSHeader from '~/src/ui/fs/comm/FSHeader' // header 영역 (공통)
import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup' // Alert 팝업 (공통)
import MSPFS401P from '~/src/ui/fs/MSPFS401P' // 한줄메모등록팝업
import MSPFS308P from '~/src/ui/fs/MSPFS308P' // 고객정보 팝업
import FSMessageUtil from '~/src/ui/fs/comm/FSMessageUtil' // header 영역 (공통)

import moment from 'moment'

export default {
  /***********************************************************************************
     * Vue 파일 속성 정의 영역
  ***********************************************************************************/
  name: 'MSPFS400M',
  screenId: 'MSPFS400M',
  components: {
    'fs-header': FSHeader,
    'msp-fs-401p': MSPFS401P,
    'msp-fs-308p': MSPFS308P,
    'fs-alert-popup': FSAlertPopup,
    'fs-cust-info': FSCustInfo
  },
  /***********************************************************************************
     * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created () {
    this.fn_SetCommCode()
    // this.ds_contact = this.lv_commCode.code218;
  },
  mounted () {
    this.$bizUtil.insSrnLog('MSPFS400M')
    this.fn_init()
  },
  /***********************************************************************************
     * 화면변수 선언 영역
  ***********************************************************************************/
  data () {
    return {

      // 로그인정보
      lv_basInfo: this.getStore('fsStore').getters.getBasInfo.data,
      lv_userInfo: this.getStore('userInfo').getters.getUserInfo,

      // 공통 객체
      pHeaderObj: {
        title: '플라자별고객관리',
        step: ''
      },
      // 공통코드
      lv_commCode: {}, // 218 고객접촉
      eaiCommObj: FSCommUtil.gfn_eaiCommObj(),
      // 팝업 객체
      pPopup401Obj: {},
      pPopup308Obj: {},
      pAlertPopupObj: {},
      pCustInfoObj: {},

      cust_no: '', // 고객 번호
      cust_nm: '', // 고객명
      cust_age: '', // 고객나이

      trd_date: '', // 고객접촉날짜
      ds_detail: {
        cust_nm: '', // 고객명
        cell_no: '', // 휴대폰
        yield_lev: '', // 수익기여도
        reg_dt: '', // 고객등록일
        voc_gb: '', // VOC여부
        invest_origin_amt: '', // 투자금액
        eval_amt: '', // 평가금액
        yield_rt: '', // 수익률
        elf_gb: '', // ELF투자
        last_counsel_dt: '', // 최종상담일
        gender: '', // 고객성별
        mail_recv_gb: '' // 우편물통보처
      },
      memo: '', // 데이터 받는 형식 보고 ds_detail 포함 여부 결정할 예정
      btn_memo: false, // 한줄메모등록버튼
      btn_query: true, // 세부내용 - 입력버튼
      btn_delete: false, // 과거 상담이력 삭제 버튼

      ds_contact: [], // 고객접촉
      selectedContact: -1, // 선택된 고객접촉 index
      ds_comm: [], //
      ds_management: [], // 고객관리
      selectedManagement: -1, // 선택된 고객관리

      bfcer_fnd_cd: '', // 선택한 펀드 코드
      // 세부내용
      ds_head: [
        {
          bfcer_cust_no: '',
          touch_gb: '',
          cnsl_brkdn_reg_sno: '',
          cnsl_reg_ymd: '',
          cust_ret_accn_no: '',
          cust_cnsl_cntnt: '',
          cust_ret_fnd_cd: '',
          bfcer_cnsl_busn_frst_cd: '',
          bfcer_cnsl_busn_scd_cd: '',
          cnsl_cnvsr_eno: '',
          cust_tel_dstno_encr: '',
          cust_telof_no_encr: '',
          cust_tel_bkno_sno_encr: '',
          destn_nm: '',
          cnsl_prms_ymd: '',
          cnsl_prms_str_hr: '',
          cnsl_prms_end_hr: '',
          brief_memo_cntnt: '',
          cnsl_reg_fof_cd: '',
          cnsl_reg_staf_eno: '',
          cust_ret_fnd_nm: '',
          max_stnd_ym: '',
          msg_cd: '',
          dept_cd: ''
        }
      ],
      inpt_prct: 0.0, // 세부내용 입력 시 글자 수 체크
      // 계좌 selectBox

      // 과거상담내역 list
      ds_counsel_history: [
        // {
        //   checked                 : false, //체크값 1
        //   cnsl_reg_ymd            : '', //상담등록일자
        //   bfcer_cust_no           : '', //수익증권고객번호
        //   cnsl_brkdn_reg_sno      : '', //상담내역등록일련번호
        //   bfcer_cnsl_busn_frst_cd : '', //수익증권상담업무1차코드
        //   bfcer_cnsl_busn_scd_cd  : '', //수익증권상담업무2차코드
        //   destn_nm                : '', //행선지명
        //   cust_cnsl_cntnt         : '', //고객상담내용
        //   cnsl_prms_ymd           : '', //상담약속일자
        //   cnsl_prms_str_hr        : '', //상담약속시작시
        //   cnsl_prms_end_hr        : '', //상담약속종료시
        //   cust_tel_dstno_encr     : '', //고객전화지역번호암호화
        //   cust_telof_no_encr      : '', ///고객전화국번호암호화
        //   cust_tel_bkno_sno_encr  : '', //고객전화뒷자리일련번호암호화
        //   emp_nm                  : '', //emp명
        //   cnsl_reg_staf_eno       : '', //상담등록임직원사번
        //   bfcer_fnd_nm            : '', //고객보유펀드명
        // }
      ],
      ds_group_no: [], // 그룹리스트
      ds_group_seq_no: [], // 그룹리스트일련번호
      bfcer_cstgr_no: '', // 그룹번호
      acct_no: '', // 계좌번호

      // as is 전역 변수
      gDate: '', // this.gfn_getBusyDate(); = >application.gds_userInfo.getColumn( 0,  "busyDate" );
      brcd: '', // this.gfn_userinfo("brcd");
      proc_dt: '',
      counsel_detail: '',
      fund_cd: '',
      contact_gb: '',
      management_gb: '',
      inv_empno: '',
      cust_telno_station: '',
      cust_telno_area: '',
      cust_telno_seq: '',
      destination: '',
      meeting_date: '',
      meeting_from_time: '',
      meeting_to_time: '',
      real_yn: '',
      counsel_detail_max_size: 900, // 상담내용 최대입력길이
      // as is end
      counsel_detail_key: 0
    }
  },
  /***********************************************************************************
     * Computed 함수 정의 영역
  ***********************************************************************************/
  computed: {
    modal1 () { return this.$refs.modal1 } // 한줄메모등록 팝업
  },
  /***********************************************************************************
     * watch 함수 정의 영역
  ***********************************************************************************/
  watch: {

  },
  /***********************************************************************************
     * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {

    /******************************************************************************
       * Function명 : fn_init
       * 설명       : 초기화
    ******************************************************************************/
    fn_init () {
      this.btn_memo = true
      // this.cust_no.set_enable(true);
      this.cust_no = ''
      this.cust_nm = ''
      this.btn_query = true
      this.btn_notice = true

      this.ds_counsel_history = []
      this.ds_detail = {}

      this.trd_date = FSCommUtil.gfn_dateReplace(1, this.lv_basInfo.busyDate)
      this.ds_head[0].dept_cd = this.lv_basInfo.brcd

      this.ds_head = [
        {
          bfcer_cust_no: '',
          touch_gb: '',
          cnsl_brkdn_reg_sno: '',
          cnsl_reg_ymd: '',
          cust_ret_accn_no: '',
          cust_cnsl_cntnt: '',
          cust_ret_fnd_cd: '',
          bfcer_cnsl_busn_frst_cd: '',
          bfcer_cnsl_busn_scd_cd: '',
          cnsl_cnvsr_eno: '',
          cust_tel_dstno_encr: '',
          cust_telof_no_encr: '',
          cust_tel_bkno_sno_encr: '',
          destn_nm: '',
          cnsl_prms_ymd: '',
          cnsl_prms_str_hr: '',
          cnsl_prms_end_hr: '',
          brief_memo_cntnt: '',
          cnsl_reg_fof_cd: '',
          cnsl_reg_staf_eno: '',
          cust_ret_fnd_nm: '',
          max_stnd_ym: '',
          msg_cd: '',
          dept_cd: ''
        }
      ]
      this.ds_group_no = [] // 그룹리스트
      this.ds_group_seq_no = [] // 그룹리스트일련번호
      this.bfcer_cstgr_no = '' // 그룹번호
      this.acct_no = ''// 계좌번호
      this.selectedContact = -1 // 선택된 고객접촉 index
      this.inpt_prct = 0.0 // 세부내용글자수
      this.counsel_detail = '' // 세부내용
      this.ds_management = [] // 고객관리
      this.selectedManagement = -1 // 선택된 고객관리
    },

    /******************************************************************************
       * Function명 : fn_grid_on_click
       * 설명       : grid 클릭 시 클릭 row class 변경하기 위해 true/false 설정
    ******************************************************************************/
    fn_grid_on_click(row){
      if(row.checked){
        row.checked = false
      }else{
        row.checked = true
      }
    },

    /******************************************************************************
       * Function명 : fn_searchList
       * 설명       : 조회
    ******************************************************************************/
    fn_searchList () {
      // 한줄메모 등록 활성화
      this.btn_memo = false
      // 입력버튼 활성화
      this.btn_query = false

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'

      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570243_S'
      this.eaiCommObj.params = {
        bfcer_cnsl_busn_frst_cd: this.contact_gb, // 수익증권상담업무1차코드, Length : 3
        bfcer_cnsl_busn_scd_cd: this.management_gb, // 수익증권상담업무2차코드, Length : 3
        bfcer_cust_no: this.cust_no, // 수익증권고객번호, Length : 7
        brief_memo_cntnt: this.ds_detail.brief_memo_cntnt, // 간단메모내용, Length : 100
        cnsl_brkdn_reg_sno: '', // 상담내역등록일련번호, Length : 18
        cnsl_cnvsr_eno: this.inv_empno, // 상담권유자사번, Length : 10
        cnsl_prms_end_hr: '', // 상담약속종료시, Length : 2
        cnsl_prms_str_hr: '', // 상담약속시작시, Length : 2
        cnsl_prms_ymd: '', // 상담약속일자, Length : 8
        cnsl_reg_fof_cd: '', // 상담등록지점코드, Length : 8
        cnsl_reg_staf_eno: '', // 상담등록임직원사번, Length : 10
        cnsl_reg_ymd: moment(this.trd_date).format('YYYYMMDD'), // 상담등록일자, Length : 8
        cust_cnsl_cntnt: this.counsel_detail, // 고객상담내용, Length : 900
        cust_ret_accn_no: this.bfcer_cstgr_no, // 고객보유계좌번호, Length : 13
        cust_ret_fnd_cd: this.bfcer_fnd_cd, // 고객보유펀드코드, Length : 6
        cust_ret_fnd_nm: '', // 고객보유펀드명, Length : 256
        cust_tel_bkno_sno_encr: '', // 고객전화뒷자리일련번호암호화, Length : 24, 암호화여부=Y, Column=TELNO_ENCR
        cust_tel_dstno_encr: '', // 고객전화지역번호암호화, Length : 24, 암호화여부=Y, Column=TELNO_ENCR
        cust_telof_no_encr: '', // 고객전화국번호암호화, Length : 24, 암호화여부=Y, Column=TELNO_ENCR
        dept_cd: this.lv_basInfo.brcd, // 지점코드, Length : 9
        destn_nm: '', // 행선지명, Length : 100
        max_stnd_ym: '', // 기준년월, Length : 2
        touch_gb: '' // 터치 구분, Length : 1
      }
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)
    },

    /******************************************************************************
       * Function명 : fn_callBack
       * 설명       : 플라자고객조회 콜백
    ******************************************************************************/
    fn_callBack (response) {
      if (FSCommUtil.gfn_length(response.data.si440000_O_00VO) > 0) {
        // 등록버튼 활성화
        this.btn_memo  = false
        this.ds_detail = response.data.si440000_O_00VO[0]
        var cellNo     = response.data.si440000_O_00VO[0].cell_no
        
        this.ds_detail.cell_no = cellNo.substr(0,cellNo.lastIndexOf('-')+1) + '****' 
        // this.cust_nm = this.ds_detail.fnd_cust_nm
        // this.cust_no = this.ds_detail.bfcer_cust_no

        let t_list = response.data.si440000_O_01VO
          if (FSCommUtil.gfn_length(t_list) > 0) {
            t_list.forEach(item => {
              item.checked = false
            })
          }

        this.ds_counsel_history = t_list

        var yield_rt = this.ds_detail.yield_rt
        // yield_rt = this.gfn_numFormat(yield_rt, 2);
        if (yield_rt === '') {
          yield_rt = '0'
        }
        yield_rt = yield_rt + '%'
        this.ds_detail.yield_rt = yield_rt
      } else {
        let t_popupObj = {content: '조회 결과가 없습니다.'}
        this.fn_AlertPopup(0, t_popupObj)
      }
    },

    /******************************************************************************
       * Function명 : fn_brief_memo
       * 설명       : 한줄메모등록
    ******************************************************************************/
    fn_brief_memo (val) {
      this.eaiCommObj.lv_vm = this
      // this.eaiCommObj.trnstId = 'txTSSFS44I1';
      this.eaiCommObj.auth = 'S'

      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570247_S'
      this.eaiCommObj.params = {
        bfcer_cnsl_busn_scd_cd: '99', // 수익증권상담업무2차코드, Length : 3
        bfcer_cust_no: this.ds_detail.bfcer_cust_no,
        brief_memo_cntnt: val, // 간단메모내용
        touch_gb: '' // 터치 구분
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_brief_memo_callBack)
    },

    fn_brief_memo_callBack (response) {
      response.data.si440000_O_01VO1.forEach(item => {
        item.checked = false
      })
      var data = response.data.si440000_O_01VO1
      if (FSCommUtil.gfn_length(data) > 0) {
        this.cust_no = data[0].bfcer_cust_no
        // 재조회시 한줄메모 확인가능
        this.fn_searchList()
      }
    },
    /******************************************************************************
       * Function명 : fn_cust_no_find
       * 설명       : 고객정보 버튼 onClick 이벤트
    ******************************************************************************/
    fn_cust_no_find () {
      if (this.cust_no === '') {
        this.pPopup308Obj.bfcer_cust_no = this.cust_no
      }
      // 고객정보
      this.pPopup308Obj.popupId = 'MSPFS308P'
      this.pPopup308Obj.screenId = 'MSPFS400M'
      this.$refs.popup308.fn_Open()
    },

    /******************************************************************************
       * Function명 : fn_contact_click
       * 설명       : 고객접촉 onClick 이벤트
    ******************************************************************************/
    fn_contact_click (idx) {
      if (this.cust_no === '') {
        let t_popupObj = {content: '고객번호를 먼저 입력해 주십시오.'}
        this.fn_AlertPopup(0, t_popupObj)
      } else {
        this.selectedContact = idx
        this.contact_gb = ''
        this.management_gb = ''

        this.contact_gb = this.ds_contact[idx].value

        // this.ds_comm[0].domname = '219'
        // this.ds_comm[0].addm_expln = this.ds_contact[idx].value

        this.eaiCommObj.lv_vm = this
        // this.eaiCommObj.trnstId = 'txTSSFS44S2';
        this.eaiCommObj.auth = 'S'

        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570245_S'
        this.eaiCommObj.params = {
          domname: '219',
          addm_expln: this.ds_contact[idx].value
        }

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_contact_callBack)
      }
    },

    /******************************************************************************
       * Function명 : fn_contact_callBack
       * 설명       : 고객접촉 트랜잭션 콜백
    ******************************************************************************/
    fn_contact_callBack (response) {
      this.ds_management = response.data.tfoa01201_O_VO
    },

    /******************************************************************************
       * Function명 : fn_management_click
       * 설명       : 고객관리 onClick 이벤트
    ******************************************************************************/
    fn_management_click (idx) {
      this.selectedManagement = idx

      this.management_gb = this.ds_management[idx].dm_val
      // 입력버튼 활성화
      this.btn_query = false

      this.eaiCommObj.lv_vm = this
      // this.eaiCommObj.trnstId = 'txTSSFS44S3';
      this.eaiCommObj.auth = 'S'

      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570277_S'
      this.eaiCommObj.params = {
        proc_gb: 'S1', // 조회구분
        bfcer_cstgr_no: '', // 수익증권고객그룹번호
        bfcer_cust_no: this.ds_detail.bfcer_cust_no // 수익증권고객번호, Length : 7

      }
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_management_callBack)
    },

    fn_management_callBack (response) {
      let data = response.data.sy003200_O_00VO
      let t_list = []
      if (data.length > 0) {
        t_list.push({
          bfcer_cstgr_no: '',
          bfcer_cstgr_seq: '공통',
          bfcer_accn_no: '',
          bfcer_accn_seq: '',
          bfcer_fnd_cd: '',
          pageRowCnt: 0
        })
        data.forEach(item => {
          t_list.push(item)
        })
        this.ds_group_no = t_list
      } else {
        this.ds_group_no = []
      }
    },

    /******************************************************************************
       * Function명 : fn_groupList_onChange
       * 설명       : 그룹리스트 onChange 이벤트
    ******************************************************************************/
    fn_groupList_onChange () {
      this.eaiCommObj.lv_vm = this
      // this.eaiCommObj.trnstId = 'txTSSFS44S4';
      this.eaiCommObj.auth = 'S'

      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570278_S'
      this.eaiCommObj.params = {
        proc_gb: 'S2', // 조회구분
        bfcer_cstgr_no: this.bfcer_cstgr_no, // 수익증권고객그룹번호
        bfcer_cust_no: this.ds_detail.bfcer_cust_no // 수익증권고객번호, Length : 7

      }
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_groupList_onChange_callBack)
    },

    fn_groupList_onChange_callBack (response) {
      var data = response.data.sy003200_O_00VO
      if (FSCommUtil.gfn_length(data) > 0) {
        this.ds_group_seq_no = data
        this.acct_no = data[0].bfcer_accn_no // 제일 첫번째 계좌 세팅
      } else {
        this.ds_group_seq_no = []
      }
    },

    fn_group_seq_no_onChange (e) {
      var fndCd = ''
      this.ds_group_seq_no.forEach(item => {
        if (item.bfcer_accn_no === e) {
          fndCd = item.bfcer_fnd_cd
        }
      })
      this.bfcer_fnd_cd = fndCd
    },
    /******************************************************************************
       * Function명 : fn_counsel_detail_change
       * 설명       : 세부내용 text aria keypress event
    ******************************************************************************/
    fn_counsel_detail_change () {
      var msg_len = FSCommUtil.gfn_getByteLength3(this.counsel_detail)
      var txt = ((msg_len * 100) / 900).toFixed(1)
      let str = this.counsel_detail
      if (msg_len > 900 ) {
        let b, i, c
        for(b=i=0; c=str.charCodeAt(i);){
          if (c <= "0x00007F") b += 1
          else if (c <= "0x0007FF") b += 2
          else if (c <= "0x00FFFF") b += 3
          else b += 4
          if(b > 900) break
          i++
        }
        this.counsel_detail = str.substring(0, i)
        this.counsel_detail_key++
        let t_popupObj = {content: '입력값이 300자를 초과하였습니다.'}
        this.fn_AlertPopup(0, t_popupObj)
      } else {
        this.inpt_prct = txt
      }
    },

    /******************************************************************************
       * Function명 : fn_insert_detail
       * 설명       : 입력버튼 클릭 이벤트
    ******************************************************************************/
    fn_insert_detail () {
      var msg_len = FSCommUtil.gfn_getByteLength3(this.counsel_detail)
      let t_popupObj
      if (msg_len === 0) {
        t_popupObj = {content: '등록할 내용이 없습니다.'}
        this.fn_AlertPopup(0, t_popupObj)
        return
      } else if (msg_len > 900) {
        t_popupObj = {content: '입력값이 300자를 초과하였습니다.'}
        this.fn_AlertPopup(0, t_popupObj)
        return
      }

      t_popupObj = {
        content: '등록하시겠습니까?',
        confirm: true,
        cancel: true,
        confirmFunc: this.fn_insert
      }
      this.fn_AlertPopup(0, t_popupObj)
    },
    /******************************************************************************
       * Function명 : fn_insert
       * 설명       : 고객 메모 입력
    ******************************************************************************/
    fn_insert () {
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'

      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570244_S'
      this.eaiCommObj.params = {
        bfcer_cnsl_busn_frst_cd: this.contact_gb, // "", // 수익증권상담업무1차코드, Length : 3
        bfcer_cnsl_busn_scd_cd: this.management_gb, // 수익증권상담업무2차코드, Length : 3
        bfcer_cust_no: this.ds_detail.bfcer_cust_no, // 수익증권고객번호, Length : 7
        brief_memo_cntnt: this.ds_detail.brief_memo_cntnt, // 간단메모내용, Length : 100
        cnsl_cnvsr_eno: this.inv_empno, // 상담권유자사번, Length : 10
        cnsl_prms_end_hr: '', // 상담약속종료시, Length : 2
        cnsl_prms_str_hr: '', // 상담약속시작시, Length : 2
        cnsl_prms_ymd: '', // // 상담약속일자, Length : 8
        cnsl_reg_ymd: moment(this.trd_date).format('YYYYMMDD'), // "", // 상담등록일자, Length : 8
        cust_cnsl_cntnt: this.counsel_detail, // 고객상담내용, Length : 900
        cust_ret_accn_no: this.bfcer_cstgr_no, // 고객보유계좌번호, Length : 13
        cust_ret_fnd_cd: this.bfcer_fnd_cd, // 고객보유펀드코드, Length : 6
        cust_tel_bkno_sno_encr: '', // 고객전화뒷자리일련번호암호화, Length : 24, 암호화여부=Y, Column=TELNO_ENCR
        cust_tel_dstno_encr: '', // 고객전화지역번호암호화, Length : 24, 암호화여부=Y, Column=TELNO_ENCR
        cust_telof_no_encr: '', // 고객전화국번호암호화, Length : 24, 암호화여부=Y, Column=TELNO_ENCR
        destn_nm: '' // 행선지명, Length : 100
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_insert_delete_callBack)
    },
    /******************************************************************************
       * Function명 : fn_delete
       * 설명       : 과거상담이력 선택값 삭제하기
    ******************************************************************************/
    fn_delete () {

      var ds_delete = []
      var ds_temp = this.ds_counsel_history

      var t_msg = ''
      ds_temp.forEach(item => {
        if (item.checked) {

          if(item.bfcer_cnsl_busn_scd_cd == "스케줄/알람"){

            if(item.bfcer_cnsl_busn_frst_cd == "SMS발송"){
              t_msg ="#4425 SMS발송이력은 수정 및 삭제가 불가합니다."
            }else{
              t_msg = "#4425 스케줄/알람내용 화면에서 수정 및 삭제 가능합니다."
            }

          }else if(item.bfcer_cnsl_busn_scd_cd == "일반_SMS발송"){

            t_msg ="#4450 SMS발송이력은 수정 및 삭제가 불가합니다."

          }else{

            var data = {
              cnsl_reg_ymd: item.cnsl_reg_ymd,
              bfcer_cust_no: item.bfcer_cust_no,
              cnsl_brkdn_reg_sno: item.cnsl_brkdn_reg_sno
            }
            ds_delete.push(data)
            
          }

          
        }
      })

      

      if (ds_delete.length === 0) {
        return
      }
      this.eaiCommObj.lv_vm = this
      // this.eaiCommObj.trnstId = 'txTSSFS44D1';
      this.eaiCommObj.auth = 'S'

      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570246_S'
      this.eaiCommObj.params = {

        // ds_head와 같음
        bfcer_cnsl_busn_frst_cd: this.contact_gb, // 수익증권상담업무1차코드, Length : 3
        bfcer_cnsl_busn_scd_cd: this.management_gb, // 수익증권상담업무2차코드, Length : 3
        bfcer_cust_no: this.ds_detail.bfcer_cust_no, // 수익증권고객번호, Length : 7
        brief_memo_cntnt: this.ds_detail.brief_memo_cntnt, // 간단메모내용, Length : 100
        cnsl_brkdn_reg_sno: '', // 상담내역등록일련번호, Length : 18
        cnsl_cnvsr_eno: this.inv_empno, // 상담권유자사번, Length : 10
        cnsl_prms_end_hr: '', // 상담약속종료시, Length : 2
        cnsl_prms_str_hr: '', // 상담약속시작시, Length : 2
        cnsl_prms_ymd: '', // 상담약속일자, Length : 8
        cnsl_reg_fof_cd: '', // 상담등록지점코드, Length : 8
        cnsl_reg_staf_eno: '', // 상담등록임직원사번, Length : 10
        cnsl_reg_ymd: moment(this.trd_date).format('YYYYMMDD'), // 상담등록일자, Length : 8
        cust_cnsl_cntnt: this.counsel_detail, // 고객상담내용, Length : 900
        cust_ret_accn_no: this.bfcer_cstgr_no, // 고객보유계좌번호, Length : 13
        cust_ret_fnd_cd: this.bfcer_fnd_cd, // 고객보유펀드코드, Length : 6
        cust_ret_fnd_nm: '', // 고객보유펀드명, Length : 256
        cust_tel_bkno_sno_encr: '', // 고객전화뒷자리일련번호암호화, Length : 24, 암호화여부=Y, Column=TELNO_ENCR
        cust_tel_dstno_encr: '', // 고객전화지역번호암호화, Length : 24, 암호화여부=Y, Column=TELNO_ENCR
        cust_telof_no_encr: '', // 고객전화국번호암호화, Length : 24, 암호화여부=Y, Column=TELNO_ENCR
        dept_cd: this.lv_basInfo.brcd, // 지점코드, Length : 9
        destn_nm: '', // 행선지명, Length : 100
        max_stnd_ym: '', // 기준년월, Length : 2
        touch_gb: '', // 터치구분, Length : 1
        SI440000_O_01VO: ds_delete
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_insert_delete_callBack)
    },

    /******************************************************************************
       * Function명 : fn_insert_delete_callBack
       * 설명       : 입력/삭제 버튼 클릭 이벤트 콜백
    ******************************************************************************/
    fn_insert_delete_callBack (response) {
      if ( response.data.error_msg === '809903' ) {
        let t_popupObj = {content: FSMessageUtil.commMessage([])[response.data.error_msg]}
        this.fn_AlertPopup(0, t_popupObj)

        response.data.si440000_O_01VO.forEach(item => {
          item.checked = false
        })
        this.ds_counsel_history = response.data.si440000_O_01VO
        // 세부내용 입력 초기화
        this.counsel_detail = ''
        this.inpt_prct = 0.0
      } else {
        let t_popupObj = {content: FSMessageUtil.commMessage([])[response.data.error_msg]}
        this.fn_AlertPopup(0, t_popupObj)
        this.btn_confirm = false
      }

      this.counsel_detail = ''
      this.fn_searchList()
    },

    /******************************************************************************
       * Function명 : fn_CustInfo, fn_SetEdCustName
       * 설명       : 고객정보조회
    ******************************************************************************/
    fn_CustInfo () {
      console.log('fn_CustInfo.....')
      if (FSCommUtil.gfn_length(this.cust_no) < 7) {
        this.cust_nm = ''
        return
      }
      this.pCustInfoObj.custNo = FSCommUtil.gfn_substr(this.cust_no, 0, 7)
      this.$refs.fsCustInfo.fn_CustInfoSearch()
    },

    fn_SetEdCustName (val) {
      // 고객명
      console.log('fn_SetEdCustName', val)
      this.cust_nm = val
    },

    /******************************************************************************
       * Function명 : fn_dateFormat fn_comma
       * 설명       : 데이터 형식 변경
    ******************************************************************************/
    fn_dateFormat (val) {
      if (val === '' || val == null) {

      } else {
        val = moment(val).format('YYYY-MM-DD')
        return val
      }
    },

    fn_comma (val) {
      if (val === '' || val == null) {

      } else {
        val = this.$bizUtil.numberWithCommas(val)
        return val
      }
    },
    /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
    ******************************************************************************/
    fn_AlertPopup (type, pPopupObj) {
      if ( !FSCommUtil.gfn_isNull(pPopupObj) ) {
        this.pAlertPopupObj.content = pPopupObj.content
        this.pAlertPopupObj.confirm = false
        this.pAlertPopupObj.cancel = true

        if ( !pPopupObj.cancel ) {
          this.pAlertPopupObj.cancel = pPopupObj.cancel
        }
        if ( pPopupObj.confirm ) {
          this.pAlertPopupObj.confirm = pPopupObj.confirm
          this.pAlertPopupObj.confirmFunc = pPopupObj.confirmFunc
          this.pAlertPopupObj.confirmData = pPopupObj.confirmData
        }
        if ( pPopupObj.closeFunc ) {
          this.pAlertPopupObj.closeFunc = pPopupObj.closeFunc
          this.pAlertPopupObj.closeData = pPopupObj.closeData
        }
      }
      this.$refs.alertPopup.fn_Open()
    },

    /******************************************************************************
       * Function명 : fn_SetCommCode, fn_SetCommCodeResult
       * 설명       : 공통코드 세팅
    ******************************************************************************/
    fn_SetCommCode () {
      console.log('fn_SetCommCode...')

      let params = [
        {'cId': '218', 'dayChkYn': 'N'}
      ]
      FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
    },

    fn_SetCommCodeResult (pResult) {
      this.lv_commCode = pResult
      let t_list = []
      pResult.code218.forEach(item => {
        if (item.value !== '005' ) {
          t_list.push(item)
        }
      })
      this.ds_contact = t_list// this.lv_commCode.code218;
    },

    /******************************************************************************
       * Function명 : fn_OpenPopup
       * 설명       : popup 컴포넌트 호출
       * 타입(type) : 0: 고객정보
    ******************************************************************************/
    fn_OpenPopup () {
      this.$refs.popup401.fn_Open()
    },

    /******************************************************************************
       * Function명 : fn_Popup_CallBack
       * 설명       : popup 컴포넌트에서 callback
       * 타입(type) : fn_Popup_CallBack = alert, fn_callBack308 = 308P
    ******************************************************************************/
    fn_Popup_CallBack (type, pData) {
      if (type === 0) {

      } else {
        this.fn_brief_memo(pData)
      }
    },

    fn_callBack308 (type, pData, pSubId) {
      if (pData !== '') {
        this.cust_no = pData
        this.fn_CustInfo()
      }
    }
  }// methods end
}
</script>
<style scoped>
</style>
