/*
 * 업무구분: 수익증권>고객>플라자별고객관리>플라자별고객관리>한줄메모등록_P
 * 화 면 명: MSPFS401P
 * 화면설명: 한줄메모등록 POPUP
 * 작 성 일: 2023.03.30
 * 작 성 자: 이지수
 */
<template>

    <!-- 한줄메모등록 팝업 -->
    <mo-modal class="fts-modal medium memo" ref="modal" title="한줄메모등록">
      <template>
        <div class="w100p pt-5">
          <div class="wrap-input row">
            <mo-text-field v-model="cust_memo" maxlength="33" placeholder="내용을 입력하고 등록 선택 시 한줄메모 항목에 등록 내용이 노출됩니다." />
            <!-- 미입력시 error클래스 추가 및 snackbar 메세지 나옴 -->
          </div>
        </div>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button point size="large" @click="fn_Close(0)">닫기</mo-button>
          <mo-button primary size="large" @click="fn_Close(1)">등록</mo-button>
        </div>
      </template>
    </mo-modal>

</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역
 ***********************************************************************************/

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역
   ***********************************************************************************/
  name: 'MSPFS401P',
  screenId: 'MSPFS401P',
  components: {},
  /***********************************************************************************
   * Life Cycle 함수 정의 영역
   ***********************************************************************************/
  created () {},
  mounted () {
    this.$bizUtil.insSrnLog('MSPFS401P')
  },
  /***********************************************************************************
   * 화면변수 선언 영역
   ***********************************************************************************/
  data () {
    return {
      cust_memo: '' // 한줄메모
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역
   ***********************************************************************************/
  computed: {
    // modal
    modal () { return this.$refs.modal }

  },
  /***********************************************************************************
   * watch 함수 정의 영역
   ***********************************************************************************/
  watch: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역
   ***********************************************************************************/
  methods: {
    /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
       ******************************************************************************/
    fn_AlertPopup (msg) {
      let t_popupObj = {
        confirm: false,
        content: msg
      }
      this.$emit('fs-alert-popup', 0, t_popupObj)
    },

    /******************************************************************************
       * Function명 : fn_init
       * 설명       : 초기화
       ******************************************************************************/
    fn_init (msg) {
      this.cust_memo = ''
    },

    /***********************************************************************************
      * Function명 : fn_Open
      * 설명       : 팝업 open
      ***********************************************************************************/
    fn_Open () {
      this.modal.open()
    },
    /***********************************************************************************
      * Function명 : fn_close
      * 설명       : 팝업 close
      ***********************************************************************************/
    fn_Close (val) {
      if (val === 0) {
        this.$emit('fs-popup-callback', 0)
      } else {
        console.log('팝업 닫기', this.cust_memo)
        this.$emit('fs-popup-callback', 1, this.cust_memo)
      }
      this.modal.close(0)
    }

  }
}
</script>
<style scoped>
</style>
